* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  color: black;
  font-size: 1.8rem;
  line-height: 1.6;
  overflow: auto;
}
h1 {
  font-size: 4.5rem;
}
h2 {
  font-size: 3.5rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.75;
}
p {
  font-size: 1.6rem;
}
a {
  text-decoration: none;
}
.bold {
  font-weight: 700;
}
.backgroundImage {
  width: 90%;
  height: 90vh;
  position: fixed;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Roboto', sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #24292e;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  box-sizing: border-box;
  min-width: 200px;
  width: 100%;
  padding: 3.5%;
}
@media (max-width: 500px) {
  .markdown-body {
    padding: 0;
  }
}
.markdown-body:after {
  width: 100%;
  height: 3.5%;
  display: inline-block;
}
.markdown-body a {
  background-color: initial;
  color: #0366d6;
  text-decoration: none;
}
.markdown-body a:active,
.markdown-body a:hover {
  outline-width: 0;
}
.markdown-body a:hover {
  text-decoration: underline;
}
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}
.markdown-body h1 .octicon-link,
.markdown-body h2 .octicon-link,
.markdown-body h3 .octicon-link,
.markdown-body h4 .octicon-link,
.markdown-body h5 .octicon-link,
.markdown-body h6 .octicon-link {
  color: #1b1f23;
  vertical-align: middle;
  visibility: hidden;
}
.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
  text-decoration: none;
}
.markdown-body h1:hover .anchor .octicon-link,
.markdown-body h2:hover .anchor .octicon-link,
.markdown-body h3:hover .anchor .octicon-link,
.markdown-body h4:hover .anchor .octicon-link,
.markdown-body h5:hover .anchor .octicon-link,
.markdown-body h6:hover .anchor .octicon-link {
  visibility: visible;
}
.markdown-body h1:hover .anchor .octicon-link:before,
.markdown-body h2:hover .anchor .octicon-link:before,
.markdown-body h3:hover .anchor .octicon-link:before,
.markdown-body h4:hover .anchor .octicon-link:before,
.markdown-body h5:hover .anchor .octicon-link:before,
.markdown-body h6:hover .anchor .octicon-link:before {
  width: 16px;
  height: 16px;
  content: ' ';
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' width='16' height='16' aria-hidden='true'%3E%3Cpath fill-rule='evenodd' d='M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z'%3E%3C/path%3E%3C/svg%3E");
}
.markdown-body h1,
.markdown-body h2 {
  padding-bottom: 0.3em;
  border-bottom: 1px solid #eaecef;
}
.markdown-body h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.markdown-body h2 {
  font-size: 1.5em;
}
.markdown-body h3 {
  font-size: 1.25em;
}
.markdown-body h4 {
  font-size: 1em;
}
.markdown-body h5 {
  font-size: 0.875em;
}
.markdown-body h6 {
  font-size: 0.85em;
  color: #6a737d;
}
.markdown-body img {
  border-style: none;
  max-width: 100%;
  box-sizing: initial;
  background-color: #fff;
}
.markdown-body img[align=right] {
  padding-left: 20px;
}
.markdown-body img[align=left] {
  padding-right: 20px;
}
.markdown-body input {
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  margin: 0;
}
.markdown-body p {
  margin-top: 0;
  margin-bottom: 10px;
}
.markdown-body strong {
  font-weight: inherit;
  font-weight: bolder;
  font-weight: 600;
}
.markdown-body ul {
  list-style-type: circle;
}
.markdown-body .octicon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}
.markdown-body .anchor {
  float: left;
  line-height: 1;
  margin-left: -20px;
  padding-right: 4px;
}
.markdown-body .anchor:focus {
  outline: none;
}
.markdown-body details {
  display: block;
}
.markdown-body details summary {
  cursor: pointer;
}
.markdown-body summary {
  display: list-item;
}
.markdown-body code,
.markdown-body kbd,
.markdown-body pre {
  font-family: monospace,monospace;
  font-size: 1em;
}
.markdown-body hr {
  box-sizing: initial;
  height: 0;
  overflow: visible;
}
.markdown-body [type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
.markdown-body * {
  box-sizing: border-box;
}
.markdown-body hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #eee;
}
.markdown-body hr:after,
.markdown-body hr:before {
  display: table;
  content: "";
}
.markdown-body hr:after {
  clear: both;
}
.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #d1d5da;
}
.markdown-body blockquote {
  margin: 0;
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}
.markdown-body blockquote > :first-child {
  margin-top: 0;
}
.markdown-body blockquote > :last-child {
  margin-bottom: 0;
}
.markdown-body ol,
.markdown-body ul {
  padding-left: 2em;
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman;
}
.markdown-body ol ol ol,
.markdown-body ol ul ol,
.markdown-body ul ol ol,
.markdown-body ul ul ol {
  list-style-type: lower-alpha;
}
.markdown-body blockquote,
.markdown-body details,
.markdown-body dl,
.markdown-body ol,
.markdown-body p,
.markdown-body pre,
.markdown-body table,
.markdown-body ul {
  margin-top: 0;
  margin-bottom: 16px;
}
.markdown-body ol ol,
.markdown-body ol ul,
.markdown-body ul ol,
.markdown-body ul ul {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body li {
  word-wrap: break-all;
}
.markdown-body li > p {
  margin-top: 16px;
}
.markdown-body li + li {
  margin-top: 0.25em;
}
.markdown-body dd {
  margin-left: 0;
}
.markdown-body dl {
  padding: 0;
}
.markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}
.markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
.markdown-body code,
.markdown-body pre {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
}
.markdown-body pre {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body input::-webkit-inner-spin-button,
.markdown-body input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}
.markdown-body :checked + .radio-label {
  position: relative;
  z-index: 1;
  border-color: #0366d6;
}
.markdown-body .border {
  border: 1px solid #e1e4e8 !important;
}
.markdown-body .border-0 {
  border: 0!important;
}
.markdown-body .border-bottom {
  border-bottom: 1px solid #e1e4e8 !important;
}
.markdown-body .rounded-1 {
  border-radius: 3px!important;
}
.markdown-body .bg-white {
  background-color: #fff !important;
}
.markdown-body .bg-gray-light {
  background-color: #fafbfc !important;
}
.markdown-body .text-gray-light {
  color: #6a737d !important;
}
.markdown-body .mb-0 {
  margin-bottom: 0!important;
}
.markdown-body .my-2 {
  margin-top: 8px!important;
  margin-bottom: 8px!important;
}
.markdown-body .pl-0 {
  padding-left: 0!important;
}
.markdown-body .py-0 {
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.markdown-body .pl-1 {
  padding-left: 4px!important;
}
.markdown-body .pl-2 {
  padding-left: 8px!important;
}
.markdown-body .py-2 {
  padding-top: 8px!important;
  padding-bottom: 8px!important;
}
.markdown-body .pl-3,
.markdown-body .px-3 {
  padding-left: 16px!important;
}
.markdown-body .px-3 {
  padding-right: 16px!important;
}
.markdown-body .pl-4 {
  padding-left: 24px!important;
}
.markdown-body .pl-5 {
  padding-left: 32px!important;
}
.markdown-body .pl-6 {
  padding-left: 40px!important;
}
.markdown-body .f6 {
  font-size: 12px!important;
}
.markdown-body .lh-condensed {
  line-height: 1.25 !important;
}
.markdown-body .text-bold {
  font-weight: 600!important;
}
.markdown-body .pl-c {
  color: #6a737d;
}
.markdown-body .pl-c1,
.markdown-body .pl-s .pl-v {
  color: #005cc5;
}
.markdown-body .pl-e,
.markdown-body .pl-en {
  color: #6f42c1;
}
.markdown-body .pl-s .pl-s1,
.markdown-body .pl-smi {
  color: #24292e;
}
.markdown-body .pl-ent {
  color: #22863a;
}
.markdown-body .pl-k {
  color: #d73a49;
}
.markdown-body .pl-pds,
.markdown-body .pl-s,
.markdown-body .pl-s .pl-pse .pl-s1,
.markdown-body .pl-sr,
.markdown-body .pl-sr .pl-cce,
.markdown-body .pl-sr .pl-sra,
.markdown-body .pl-sr .pl-sre {
  color: #032f62;
}
.markdown-body .pl-smw,
.markdown-body .pl-v {
  color: #e36209;
}
.markdown-body .pl-bu {
  color: #b31d28;
}
.markdown-body .pl-ii {
  color: #fafbfc;
  background-color: #b31d28;
}
.markdown-body .pl-c2 {
  color: #fafbfc;
  background-color: #d73a49;
}
.markdown-body .pl-c2:before {
  content: "^M";
}
.markdown-body .pl-sr .pl-cce {
  font-weight: 700;
  color: #22863a;
}
.markdown-body .pl-ml {
  color: #735c0f;
}
.markdown-body .pl-mh,
.markdown-body .pl-mh .pl-en,
.markdown-body .pl-ms {
  font-weight: 700;
  color: #005cc5;
}
.markdown-body .pl-mi {
  font-style: italic;
  color: #24292e;
}
.markdown-body .pl-mb {
  font-weight: 700;
  color: #24292e;
}
.markdown-body .pl-md {
  color: #b31d28;
  background-color: #ffeef0;
}
.markdown-body .pl-mi1 {
  color: #22863a;
  background-color: #f0fff4;
}
.markdown-body .pl-mc {
  color: #e36209;
  background-color: #ffebda;
}
.markdown-body .pl-mi2 {
  color: #f6f8fa;
  background-color: #005cc5;
}
.markdown-body .pl-mdr {
  font-weight: 700;
  color: #6f42c1;
}
.markdown-body .pl-ba {
  color: #586069;
}
.markdown-body .pl-sg {
  color: #959da5;
}
.markdown-body .pl-corl {
  text-decoration: underline;
  color: #032f62;
}
.markdown-body .mb-0 {
  margin-bottom: 0!important;
}
.markdown-body .my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px!important;
}
.markdown-body .pl-0 {
  padding-left: 0!important;
}
.markdown-body .py-0 {
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.markdown-body .pl-1 {
  padding-left: 4px!important;
}
.markdown-body .pl-2 {
  padding-left: 8px!important;
}
.markdown-body .py-2 {
  padding-top: 8px!important;
  padding-bottom: 8px!important;
}
.markdown-body .pl-3 {
  padding-left: 16px!important;
}
.markdown-body .pl-4 {
  padding-left: 24px!important;
}
.markdown-body .pl-5 {
  padding-left: 32px!important;
}
.markdown-body .pl-6 {
  padding-left: 40px!important;
}
.markdown-body .pl-7 {
  padding-left: 48px!important;
}
.markdown-body .pl-8 {
  padding-left: 64px!important;
}
.markdown-body .pl-9 {
  padding-left: 80px!important;
}
.markdown-body .pl-10 {
  padding-left: 96px!important;
}
.markdown-body .pl-11 {
  padding-left: 112px!important;
}
.markdown-body .pl-12 {
  padding-left: 128px!important;
}
.markdown-body:after,
.markdown-body:before {
  display: table;
  content: "";
}
.markdown-body:after {
  clear: both;
}
.markdown-body > :first-child {
  margin-top: 0!important;
}
.markdown-body > :last-child {
  margin-bottom: 0!important;
}
.markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: 100%;
  overflow: auto;
}
.markdown-body table th {
  font-weight: 600;
}
.markdown-body table td,
.markdown-body table th {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}
.markdown-body table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}
.markdown-body table tr:nth-child(2n) {
  background-color: #f6f8fa;
}
.markdown-body code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}
.markdown-body pre > code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}
.markdown-body .highlight {
  margin-bottom: 16px;
}
.markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}
.markdown-body pre {
  word-wrap: normal;
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}
.markdown-body pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: initial;
  border: 0;
}
.markdown-body .commit-tease-sha {
  display: inline-block;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 90%;
  color: #444d56;
}
.markdown-body .full-commit .btn-outline:not(:disabled):hover {
  color: #005cc5;
  border-color: #005cc5;
}
.markdown-body .blob-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}
.markdown-body .blob-wrapper-embedded {
  max-height: 240px;
  overflow-y: auto;
}
.markdown-body .blob-num {
  width: 1%;
  min-width: 50px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  line-height: 20px;
  color: rgba(27, 31, 35, 0.3);
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.markdown-body .blob-num:hover {
  color: rgba(27, 31, 35, 0.6);
}
.markdown-body .blob-num:before {
  content: attr(data-line-number);
}
.markdown-body .blob-code {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  line-height: 20px;
  vertical-align: top;
}
.markdown-body .blob-code-inner {
  overflow: visible;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  color: #24292e;
  word-wrap: normal;
  white-space: pre;
}
.markdown-body .pl-token:active,
.markdown-body .pl-token:hover {
  cursor: pointer;
  background: #ffea7f;
}
.markdown-body .tab-size[data-tab-size="2"] {
  -moz-tab-size: 2;
  tab-size: 2;
}
.markdown-body .tab-size[data-tab-size="1"] {
  -moz-tab-size: 1;
  tab-size: 1;
}
.markdown-body .tab-size[data-tab-size="3"] {
  -moz-tab-size: 3;
  tab-size: 3;
}
.markdown-body .tab-size[data-tab-size="4"] {
  -moz-tab-size: 4;
  tab-size: 4;
}
.markdown-body .tab-size[data-tab-size="5"] {
  -moz-tab-size: 5;
  tab-size: 5;
}
.markdown-body .tab-size[data-tab-size="6"] {
  -moz-tab-size: 6;
  tab-size: 6;
}
.markdown-body .tab-size[data-tab-size="7"] {
  -moz-tab-size: 7;
  tab-size: 7;
}
.markdown-body .tab-size[data-tab-size="8"] {
  -moz-tab-size: 8;
  tab-size: 8;
}
.markdown-body .tab-size[data-tab-size="9"] {
  -moz-tab-size: 9;
  tab-size: 9;
}
.markdown-body .tab-size[data-tab-size="10"] {
  -moz-tab-size: 10;
  tab-size: 10;
}
.markdown-body .tab-size[data-tab-size="11"] {
  -moz-tab-size: 11;
  tab-size: 11;
}
.markdown-body .tab-size[data-tab-size="12"] {
  -moz-tab-size: 12;
  tab-size: 12;
}
.markdown-body .task-list-item {
  list-style-type: none;
}
.markdown-body .task-list-item input {
  margin: 0 0.2em 0.25em -1.6em;
  vertical-align: middle;
}
.markdown-body .task-list-item + .task-list-item {
  margin-top: 3px;
}
header {
  position: sticky;
  top: 0;
  z-index: 9;
  background: white;
  border-bottom: 1px solid lightsteelblue;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 100;
  height: 75px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0% 0.8%;
}
header nav {
  min-width: 30%;
  max-width: 80%;
  display: flex;
  justify-content: flex-end;
}
header nav h3 {
  font-size: 1.3rem;
  font-weight: 800;
  color: #1351a8;
  margin: 0 10px;
}
header nav h3:hover {
  color: #F06A67;
}
@media (max-width: 1024px) {
  header nav h3 {
    font-size: 1rem;
  }
}
@media (max-width: 500px) {
  header nav h3 {
    margin: 10px 5px 0 0;
    font-size: 0.75rem;
  }
}
header nav button {
  width: 110px;
  background: #ffffff;
  padding: 2%;
  border: 3px solid #1351a8;
  color: #1351a8;
  font-size: 1rem;
  font-weight: 501;
}
header nav button:hover {
  background: #F06A67;
  border: 3px solid #F06A67;
  color: #ffffff;
}
@media (max-width: 800px) {
  header nav button {
    padding: 1%;
  }
}
@media (max-width: 500px) {
  header nav button {
    padding: 1% 0;
    font-size: 0.75rem;
    width: 80px;
  }
}
@media (max-width: 500px) {
  header nav {
    display: none;
  }
}
@media (max-width: 800px) {
  header {
    height: 80px;
  }
}
@media (max-width: 500px) {
  header {
    margin: 0;
    padding: 0;
    height: 60px;
  }
}
.headerLogo {
  max-width: 75%;
}
.headerLogo div {
  display: flex;
}
.headerLogo h1 {
  color: #F06A67;
  font-size: 2rem;
  font-weight: 800;
  margin: auto 0;
}
@media (max-width: 500px) {
  .headerLogo h1 {
    font-size: 1.3rem;
  }
}
.headerLogo h4 {
  color: #1351a8;
  font-size: 1.1rem;
  font-weight: 1000;
  margin: 13px 0 0 5px;
}
@media (max-width: 1024px) {
  .headerLogo h4 {
    font-size: 1.1rem;
    margin: 13px 0 0 5px;
  }
}
@media (max-width: 800px) {
  .headerLogo h4 {
    font-size: 1rem;
    margin: 14px 0 0 5px;
  }
}
@media (max-width: 500px) {
  .headerLogo h4 {
    margin: 7px 0 0 5px;
  }
}
@media (max-width: 500px) {
  .headerLogo {
    margin: 0 auto;
  }
}
.headerIcon {
  display: none;
  font-size: 26px;
  color: #7289da;
}
@media (max-width: 500px) {
  .headerIcon {
    display: unset;
  }
}
.contact {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 500px) {
  .contact {
    display: none;
  }
}
.contactIcon1 {
  margin: 0 10px;
  font-size: 45px;
  color: #7289da;
}
@media (max-width: 800px) {
  .contactIcon1 {
    margin: 0 5px 0 0;
  }
}
@media (max-width: 500px) {
  .contactIcon1 {
    margin: 0 10px 0 0;
  }
}
.contactIcon2 {
  margin: 0 10px 0 10px;
  font-size: 45px;
  color: #7289da;
}
@media (max-width: 800px) {
  .contactIcon2 {
    margin: 0 5px 0 0;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  width: 95%;
  height: 65px;
  margin: 0 auto;
}
@media (max-width: 500px) {
  .footer {
    width: 100%;
  }
}
.footer button {
  border: 1px solid #1351a8;
  border-radius: 6px;
  width: 120px;
  height: 35px;
  font-weight: 550;
  color: whitesmoke;
  background-color: #F06A67;
}
.footer button:hover {
  border: 1px solid #F06A67;
  background-color: #1351a8;
  cursor: pointer;
}
.four-oh-four {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.four-oh-four img {
  width: 100%;
  max-width: 500px;
  margin: 0 auto 20px auto;
}
.four-oh-four p {
  text-align: center;
}
.content-main {
  display: flex;
  width: 100%;
  height: calc(100vh - 75px);
  justify-content: space-between;
  overflow-y: auto;
}
@media (max-width: 800px) {
  .content-main {
    height: calc(100vh - 80px);
  }
}
@media (max-width: 500px) {
  .content-main {
    height: calc(100vh - 60px);
  }
}
.contentDiv {
  margin: 0 auto;
  width: 980px;
  max-width: 65%;
}
@media (max-width: 500px) {
  .contentDiv {
    max-width: 98%;
    padding: 4%;
    margin-top: 0;
  }
}
.sidebar {
  position: sticky;
  top: 0;
  width: 25%;
  min-width: 150px;
  max-width: 300px;
  height: 100%;
}
.sidebar:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 1%;
  height: 98%;
  width: 100%;
  border-right: 3px solid #eaecef;
  border-radius: 2%;
}
@media (max-width: 800px) {
}
@media (max-width: 500px) {
  .sidebar {
    display: none;
    position: fixed;
    top: 60px;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    z-index: 10;
  }
}
.sidebar-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.sidebar-child {
  display: flex;
  flex-direction: column;
  padding: 5%;
}
.sidebar-folder {
  display: flex;
  flex-direction: column;
  padding: 3% 5% 0 5%;
  margin: 0;
}
.sidebar-folder div:first-child {
  display: flex;
  justify-content: space-between;
  margin: 0 0 5px 0;
  cursor: pointer;
}
.sidebar-folder div:first-child svg {
  width: 20px;
}
.sidebar-folder a {
  color: black;
  text-decoration: none;
  margin: 0 0 5px 10px;
}
.sidebar-folder a:hover {
  color: #F06A67;
}
.sidebar-rotate-up {
  animation: chevron-rotate-up-reverse 0.5s forwards;
  animation-iteration-count: 1;
}
.sidebar-unRotate-up {
  animation: chevron-rotate-up 0.5s forwards;
  animation-iteration-count: 1;
}
@keyframes chevron-rotate-up {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
@keyframes chevron-rotate-up-reverse {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.active {
  color: #F06A67 !important;
  cursor: default;
}
.table-of-contents {
  position: sticky;
  top: 0;
  width: 25%;
  min-width: 150px;
  max-width: 300px;
  height: 100%;
}
.table-of-contents:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 1%;
  right: 0;
  height: 98%;
  width: 100%;
  border-left: 3px solid #eaecef;
  border-radius: 2%;
}
@media (max-width: 800px) {
  .table-of-contents {
    display: none;
  }
}
@media (max-width: 500px) {
  .table-of-contents {
    position: fixed;
    top: 60px;
    right: 0;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    z-index: 10;
  }
}
.toc-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.toc-child {
  display: flex;
  flex-direction: column;
  padding: 10%;
}
.toc-child h2 {
  font-weight: 600;
  margin: 0 0 6px -10px;
}
.toc-child a {
  color: black;
  text-decoration: none;
  margin: 0 0 3px 10px;
}
.toc-child a:hover {
  color: #F06A67;
}
